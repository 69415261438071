$border-color: #dee2e6;
$control-border-color: #e9ecef;
$highlight-color: #3B82F6;

$color-blue-50: var(--blue-50);
$color-blue-100: var(--blue-100);
$color-blue-200: var(--blue-200);
$color-blue-300: var(--blue-300);
$color-blue-400: var(--blue-400);
$color-blue-500: var(--blue-500);
$color-blue-600: var(--blue-600);
$color-blue-700: var(--blue-700);
$color-blue-800: var(--blue-800);
$color-blue-900: var(--blue-900);

$color-gray-100: var(--gray-100);
$color-gray-200: var(--gray-200);
$color-gray-300: var(--gray-300);
$color-gray-400: var(--gray-400);
$color-gray-500: var(--gray-500);
$color-gray-600: var(--gray-600);
$color-gray-700: var(--gray-700);
$color-gray-800: var(--gray-800);
$color-gray-900: var(--gray-900);


$image-width-500: 500px;

$image-width-300: 300px;
$image-height-300: 300px;

$image-width-250: 250px;
$image-height-250: 250px;

$image-width-200: 200px;
$image-height-200: 200px;

$image-width-150: 150px;
$image-height-150: 150px;
