.p-component {
  font-size: 1rem !important;
  font-family: "Nunito Sans", sans-serif !important;
}

.p-component,
.p-component *,
.p-focus {
  box-shadow: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.p-dropdown.form-control {
  .p-inputtext {
    padding: 0;
  }
}

.p-sidebar {
  width: 100% !important;
  background: rgba(36, 49, 63, 0.8) !important;
  background-blend-mode: multiply !important;

  @media screen and (min-width: 360px) {
    width: 360px !important;
  }
}

.p-panelmenu {
  .p-panelmenu-header-link {
    background: none !important;
    color: #fff !important;
    font-family: "DM Sans" !important;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 35px !important;
  }

  .p-panelmenu-content {
    background: none;
  }

  .p-menuitem-text {
    color: #fff !important;
  }

  .p-panelmenu-icon {
    color: #fff !important;
  }

  .p-menuitem-link {
    &:not(.p-disabled):hover {
      background: none !important;
    }
  }
}

.dch-menu.p-menubar {
  width: 100%;
  padding: 20px 30px;
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  // border-bottom: 0.5px solid #fff;

  @media screen and (min-width: 768px) {
    padding: 40px 75px;
  }
}

.p-button {
  background: #3e60d4;
  border-color: #3e60d4;
  width: 100%;
}

.p-button-link {
  color: #000 !important;
  text-align: left !important;
  font-family: "Saira", sans-serif !important;
}

p-dropdown.ng-touched.ng-invalid {
  > .p-dropdown {
    border-color: #f62d51;
  }
}

p-calendar.ng-touched.ng-invalid {
  .p-inputtext {
    border-color: #f62d51;
  }
}

p-autocomplete.ng-touched.ng-invalid {
  .p-inputtext {
    border-color: #f62d51;
  }
}

// tabs in Contact Us page
.contact-us {
  .p-tabview-nav {
    border-color: transparent;
    justify-content: center;

    li .p-tabview-nav-link,
    li.p-highlight .p-tabview-nav-link,
    li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      border-color: transparent;
    }

    // li:not(:first-child) {
    //   .p-tabview-nav-link::before {
    //     content: "|";
    //     position: absolute;
    //     left: 0;
    //   }
    // }

    li .p-tabview-nav-link {
      color: #000;
      font-weight: normal;
    }

    // li.p-highlight .p-tabview-nav-link {
    //   text-decoration: underline;
    // }
  }
}

// autocomplete dropdown
.p-autocomplete .p-autocomplete-panel {
  max-width: 100%;
}

// dropdown
.p-dropdown {
  .p-dropdown-panel {
    max-width: 100%;

    .p-dropdown-items .p-dropdown-item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .p-dropdown-filter {
      width: 100%;
    }
  }
}

// top notification
.top-notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  .p-message {
    margin: 0;
    .p-message-wrapper {
      padding: 0.25rem 1rem;
    }
  }
}

a.hash-tag {
  text-decoration: none;
}
.p-tooltip .p-tooltip-text {
  padding: 0px;
}
