html, body {
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}


.text-blue-50 {
  color: $color-blue-50;
}
.text-blue-100 {
  color: $color-blue-100;
}
.text-blue-200 {
  color: $color-blue-200;
}
.text-blue-300 {
  color: $color-blue-300;
}
.text-blue-400 {
  color: $color-blue-400;
}
.text-blue-500 {
  color: $color-blue-500;
}
.text-blue-600 {
  color: $color-blue-600;
}
.text-blue-700 {
  color: $color-blue-700;
}
.text-blue-800 {
  color: $color-blue-800;
}
.text-blue-900 {
  color: $color-blue-900;
}


.text-gray-100 {
  color: $color-gray-100;
}
.text-gray-200 {
  color: $color-gray-200;
}
.text-gray-300 {
  color: $color-gray-300;
}
.text-gray-400 {
  color: $color-gray-400;
}
.text-gray-500 {
  color: $color-gray-500;
}
.text-gray-600 {
  color: $color-gray-600;
}
.text-gray-700 {
  color: $color-gray-700;
}
.text-gray-800 {
  color: $color-gray-800;
}
.text-gray-900 {
  color: $color-gray-900;
}

.title-setion {
  margin: 1rem 0;
  color: #214862;
}

small.text-danger {
  display: none;
}

.ng-touched.ng-invalid {
  border-color: #f62d51;

  ~ small.text-danger {
    display: block;
  }
}

.wrapper {
  background-color: #f5f0f1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
}

.w-0 {
  width: 0 !important;
}

.h-0 {
  height: 0 !important;
}

label.required:after {
  content: "*";
  color: #f62d51;
}

.fw-semi-bold {
  font-weight: 600;
}
