@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  height: 100%;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

body::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.rounded-input {
  border-radius: 10px;
  background-color: white !important;
  padding: 10px;
}

.btn-mobile-menu-container {
  padding: 10px 10px !important;
}

.mobile-menu-sidenav {
  padding: 10px;
}

.style-success {
  color: #fff;
  background-color: #36bea6;
}

.style-success .mat-simple-snackbar-action {
  color: #fff;
}

.style-error {
  color: #fff;
  background-color: #f62d51;
}

.style-error .mat-simple-snackbar-action {
  color: #fff;
}

.limo-fare-tabs {
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
}

.limo-fare-tabs li {
  display: flex;
  vertical-align: top;
  font-size: 16px;
  background: #e5e5e5;
  color: #203e5b;
  height: 50px;
  width: calc(25% - 3.75px);
  text-align: center;
  margin-right: 5px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 1.2em;
  border: 0;
  border-bottom: none;
  position: relative;
  place-items: center;
  justify-content: center;
  /* box-shadow: 8px 9px 5px -8px rgba(0,0,0,0.25); */
}

.limo-fare-tabs li:last-child {
  margin-right: 0;
}

.limo-fare-tabs li:hover,
.limo-fare-tabs li.active {
  background: #ffffff;
  color: #000;
  border-top: 1px solid #feb226;
}

.limo-fare-tabs li a {
  font-weight: 600;
}
